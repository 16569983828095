import uniqid from "uniqid";
import { API_HOST } from "../api_utils/index";
import { showGrowingSpinner, hideGrowingSpinner } from '../spinner'; // Adjust the path as needed

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CustomDynamicCarouselPlugin = (editor, options) =>{
	console.log('CustomDynamicCarouselPlugin options', options);

	const notifyTheme = {
		position: "top-right",
		autoClose: 7500,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "light",
	};

	const notifyTheme2 = {
		position: "top-right",
		autoClose: 3000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "light",
	};

	const successTheme = {
	  position: "top-right",
	  autoClose: 5000,
	  hideProgressBar: false,
	  closeOnClick: true,
	  pauseOnHover: true,
	  draggable: true,
	  progress: undefined,
	  theme: "light",
	};

	// toast notification for empty tables
	const infoTheme = {
	  position: "top-right",
	  autoClose: 7500,
	  hideProgressBar: false,
	  closeOnClick: true,
	  pauseOnHover: true,
	  draggable: true,
	  progress: undefined,
	  theme: "light",
	};

    if (editor !== null || editor !== undefined) {
    	editor.Blocks.add("custom-dynamic-carousel-container", {
	        label: "Dynamic Carousel",
	        category: "Lists",
	        select: true,
	        media: `<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/></svg>`,
	        content: { type: "custom-dynamic-carousel-container" },
	    });

	    const dynamicCarouselScript = function(props) {
		    console.log("dynamicCarouselScript props", props);

		    let { RepetitionCount } = props;
		    let mainContainer = this;

		    let innerContainer = mainContainer.getElementsByClassName("dynamic-carousel-inner-container")[0];

		    const url = window.top.location.href;
		    const isSubstringPresent = (url.indexOf("/editor/") !== -1 && url.indexOf("?projectId=") !== -1) || url.indexOf("/large_preview/") !== -1 || url.indexOf("/tab_preview/") !== -1 || url.indexOf("/mobile_preview/") !== -1 || url.indexOf("/fragment_editor/") !== -1;

		    // Logic to loop through all elements and store their IDs in session storage
		    if (innerContainer) {
		        const idsArray = [];

		        // Function to recursively gather IDs of all elements and their children
		        const gatherIds = (element) => {
		            idsArray.push(element.id);
		            for (let i = 0; i < element.children.length; i++) {
		                gatherIds(element.children[i]);
		            }
		        };

		        // Start the recursive gathering of IDs from the innerContainer
		        gatherIds(innerContainer);

		        // Create the storage object
		        const storageKey = `DynamicCarousel_${mainContainer.id ? mainContainer.id : 'undefined'}`;
		        const storageValue = { [`${innerContainer.id}`]: idsArray };

		        // Store the object in session storage
		        sessionStorage.setItem(storageKey, JSON.stringify(storageValue));
		    }

		    // script should work only in download
		    if (!isSubstringPresent) {
		        if (innerContainer) {
		            // Calculate the starting index for new clones based on existing clones
		            let existingClones = mainContainer.getElementsByClassName("dynamic-carousel-inner-container");
		            let startIndex = existingClones.length - 1; // Start after the original container

		            // Remove all old clones, keeping only the first original container
		            for (let i = 1; i < existingClones.length; i++) {
		                mainContainer.removeChild(existingClones[i]);
		            }

		            // Reset the startIndex to 1 since we're only keeping the original container
		            startIndex = 1;

		            // Clone the innerContainer and append as per the RepetitionCount
		            for (let i = 1; i <= RepetitionCount; i++) {
		                // Clone the inner container deeply, including child elements and their styling
		                let clonedComponent = innerContainer.cloneNode(true);

		                // Copy CSS styles from the original container to the cloned container
		                copyStyles(innerContainer, clonedComponent);

		                // Append the cloned component to the main container
		                mainContainer.appendChild(clonedComponent);

		                // Add data-repl-id to the cloned component and its children
		                addDataReplId(clonedComponent, startIndex + i - 1);
		            }
		        }

		        function copyStyles(sourceNode, targetNode) {
		            // Copy styles of the source node to the target node
		            const computedStyle = window.getComputedStyle(sourceNode);
		            for (let key of computedStyle) {
		                targetNode.style[key] = computedStyle.getPropertyValue(key);
		            }

		            // Recursively copy styles for all child nodes
		            const children = sourceNode.children;
		            for (let i = 0; i < children.length; i++) {
		                copyStyles(children[i], targetNode.children[i]);
		            }
		        }

		        // Function to recursively add the data-repl-id to each component and its children
		        function addDataReplId(component, index) {
		            // Add data-repl-id to the current component
		            let currentId = component.id;
		            if (currentId.includes('_')) {
		                let newId = currentId.split('_')[0];
		                component.id = `${newId}_${index}`;
		                component.setAttribute('data-repl-id', `${newId}_${index}`);
		            } else {
		                component.id = `${currentId}_${index}`;
		                component.setAttribute('data-repl-id', `${currentId}_${index}`);
		            }

		            // Recursively add data-repl-id to all child components
		            let children = component.children;
		            for (let i = 0; i < children.length; i++) {
		                addDataReplId(children[i], index);
		            }
		        }
		    }
		};

	    editor.DomComponents.addType("custom-dynamic-carousel-container", {
	    	model: {
	    		defaults: {
	    			tagName: "div",
	    			type: "custom-dynamic-carousel-container",
	    			attributes: {
	    				class: "custom-dynamic-carousel-container",
	    			},
	    			script: dynamicCarouselScript,

	    			RepetitionCount: 0,

					customUrlValue: "",
					baseUrlValue: "",
					apiPathValue: "",
					apiMethodValue: "GET",
					apiUsernameValue: "",
					apiPasswordValue: "",
					apiBearerTokenValue: "",
					apiMoreHeadersValue: "",
					apiBodyValue: "",
					nestedArrOfObjKeynameValue: "",

					options: options,
					projectId: localStorage.getItem("project_id") ? localStorage.getItem("project_id") : "",

	    			traits: [
	    				{
	    					type: "text",
	    					label: "Repetition Count",
	    					name: "RepetitionCount",
	    					
	    					changeProp: true,
	    				},
	    				{
	    					type: "text",
	    					label: "Custom Url Value",
	    					name: "customUrlValue",
	    					
	    					changeProp: true,
	    				}, {
	    					type: "text",
	    					label: "Base Url Value",
	    					name: "baseUrlValue",
	    					
	    					changeProp: true,
	    				}, {
	    					type: "text",
	    					label: "API Path Value",
	    					name: "apiPathValue",
	    					
	    					changeProp: true,
	    				}, {
	    					type: "select",
	    					label: "API Method Value",
	    					name: "apiMethodValue",
	    					options: [
				                { id: "GET", value: "GET", name: "GET" },
				                { id: "POST", value: "POST", name: "POST" },
			                ],
	    					changeProp: true,
	    				}, {
	    					type: "text",
	    					label: "API Username Value",
	    					name: "apiUsernameValue",
	    					
	    					changeProp: true,
	    				}, {
	    					type: "text",
	    					label: "API Password Value",
	    					name: "apiPasswordValue",
	    					
	    					changeProp: true,
	    				}, {
	    					type: "text",
	    					label: "API Bearer Token Value",
	    					name: "apiBearerTokenValue",
	    					
	    					changeProp: true,
	    				}, {
	    					type: "text",
	    					label: "API More Headers Value",
	    					name: "apiMoreHeadersValue",
	    					
	    					changeProp: true,
	    				}, {
	    					type: "text",
	    					label: "API Body Value",
	    					name: "apiBodyValue",
	    					
	    					changeProp: true,
	    				}, {
	    					type: "text",
	    					label: "Nested Array Of Object Keyname Value",
	    					name: "nestedArrOfObjKeynameValue",
	    					
	    					changeProp: true,
	    				},
	    			],

	    			changeProp: true,
	    			"script-props": [
	    				"RepetitionCount",
	    				"customUrlValue",
						"baseUrlValue",
						"apiPathValue",
						"apiMethodValue",
						"apiUsernameValue",
						"apiPasswordValue",
						"apiBearerTokenValue",
						"apiMoreHeadersValue",
						"apiBodyValue",
						"nestedArrOfObjKeynameValue",
						"options",
	    			],

	    			style: {
	    				padding: "0.5em",
	    				height: "auto",
	    			},

	    			"style-default": {
	    				padding: "0.5em",
	    				height: "auto",
	    			},

	    			components: [
	    				{
	    					tagName: "div",
			    			type: "dynamic-carousel-inner-container",
			    			attributes: {
			    				class: "dynamic-carousel-inner-container",
			    			},

			    			style: {
			    				padding: "0.5em",
			    				height: "30vh",
			    			},

			    			"style-default": {
			    				padding: "0.5em",
			    				height: "30vh",
			    			},
	    				},
	    			],
	    		},

	    		init() {
	    			this.on("change:RepetitionCount", this.RepetitionCountHandler);

	    			this.on("change:customUrlValue", this.apiPlotting);
	    			this.on("change:baseUrlValue", this.apiPlotting);
	    			this.on("change:apiPathValue", this.apiPlotting);
	    			this.on("change:apiMethodValue", this.apiPlotting);
	    			this.on("change:apiUsernameValue", this.apiPlotting);
	    			this.on("change:apiPasswordValue", this.apiPlotting);
	    			this.on("change:apiBearerTokenValue", this.apiPlotting);
	    			this.on("change:apiMoreHeadersValue", this.apiPlotting);
	    			this.on("change:apiBodyValue", this.apiPlotting);
	    			this.on("change:nestedArrOfObjKeynameValue", this.apiPlotting);
	    		},

	    		removed() {
	    			// console.log('removing DynamicCarousel_', this.getAttributes().id);

	    			// only remove keys related to this component
					for (let i = 0; i < sessionStorage.length; i++) {
						const key = sessionStorage.key(i);

						// Check if the key contains the substring 'DynamicCarousel_' and remove only those when component is removed
						if (key && key.includes(`DynamicCarousel_${this.getAttributes().id}`)) {
							sessionStorage.removeItem(key);
						}
					}
					
					for (let i = 0; i < sessionStorage.length; i++) {
						const key = sessionStorage.key(i);

						// Check if the key contains the substring 'DynamicCarousel_' and remove only those when component is removed
						if (key && key.includes(`DynamicCarousel_APIResp_${this.getAttributes().id}`)) {
							sessionStorage.removeItem(key);
						}
					}
	    		},

	    		// helper function to check if a variable is an array-of-objects or not
				isArrayofObjects(variable) {
					// Check if the variable is an array
					if (!Array.isArray(variable)) {
						return false;
					}

					// Check if all elements in the array are objects
					for (const element of variable) {
						if (typeof element !== "object" || Array.isArray(element) || element === null) {
							return false;
						}
					}
					return true;
				},

	    		async apiPlotting() {
	    			let {
	    				RepetitionCount,
	    				customUrlValue,
						baseUrlValue,
						apiPathValue,
						apiMethodValue,
						apiUsernameValue,
						apiPasswordValue,
						apiBearerTokenValue,
						apiMoreHeadersValue,
						apiBodyValue,
						nestedArrOfObjKeynameValue,
						projectId,
						options,
	    			} = this.props();

	    			// api response length - 1 (because one is original div)
	    			let repetitionCount;
	    			let apiData, bu, bubt;

	    			// base url bearer token taken directly from local storage after getting downloaded
					let baseUrlData, dynamicBaseUrlBearerToken, baseUrl1BearerToken, baseUrl2BearerToken, baseUrl3BearerToken, baseUrl4BearerToken, baseUrl5BearerToken;
					// getting baseUrl data from local storage for run-time access
					let baseUrlDataFromStorage = localStorage.getItem("baseUrlData");
					if (baseUrlDataFromStorage) {
						baseUrlData = JSON.parse(baseUrlDataFromStorage);
					}
					// extracting token values from base URLs from baseUrlData
					if (baseUrlData) {
						try {
							if (`baseUrl1_${projectId}` in baseUrlData) {
								baseUrl1BearerToken = baseUrlData[`baseUrl1_${projectId}`].token;
							}
							if (`baseUrl2_${projectId}` in baseUrlData) {
								baseUrl2BearerToken = baseUrlData[`baseUrl2_${projectId}`].token;
							}
							if (`baseUrl3_${projectId}` in baseUrlData) {
								baseUrl3BearerToken = baseUrlData[`baseUrl3_${projectId}`].token;
							}
							if (`baseUrl4_${projectId}` in baseUrlData) {
								baseUrl4BearerToken = baseUrlData[`baseUrl4_${projectId}`].token;
							}
							if (`baseUrl5_${projectId}` in baseUrlData) {
								baseUrl5BearerToken = baseUrlData[`baseUrl5_${projectId}`].token;
							}
						} catch (e) {
							// statements
							console.log(e);
						}
					}

	    			// if only custom url is provided and its a GET call
					if (customUrlValue && (!baseUrlValue || baseUrlValue === "null") && !apiPathValue && apiMethodValue === "GET") {
						// if nothing is provided, for authentication
						if (!apiUsernameValue && !apiPasswordValue && !apiBearerTokenValue) {
							// add extra headers, if needed
							let config = {
								headers: {},
							};

							if (apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${customUrlValue}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if bearer token is provided but nothing else, for authentication
						else if (!apiUsernameValue && !apiPasswordValue && apiBearerTokenValue) {
							// add extra headers, if needed
							let config = {
								headers: {
									"Content-Type": "application/json",
									Authorization: `Bearer ${apiBearerTokenValue}`,
								},
							};

							if (apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${customUrlValue}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if username & password are provided but nothing else, for authentication
						else if (apiUsernameValue && apiPasswordValue && !apiBearerTokenValue) {
							// add extra headers, if needed
							let config = {
								headers: {
									Authorization: "Basic " + btoa(apiUsernameValue + ":" + apiPasswordValue),
								},
							};

							if (apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${customUrlValue}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}
					}

					// if only base url is provided and its a GET call
					else if (!customUrlValue && (baseUrlValue || !baseUrlValue === "null") && apiPathValue && apiMethodValue === "GET") {
						// concatenating base url and its remaining part
						let url, dynamicBaseUrlBearerToken;
						let forDownloadUrl;

						if (baseUrlValue === "baseUrl1") {
							url = options.baseUrl1 + apiPathValue;
							// baseUrlBearerToken = options.baseUrl1BearerToken;
							dynamicBaseUrlBearerToken = baseUrl1BearerToken;
						} else if (baseUrlValue === "baseUrl2") {
							url = options.baseUrl2 + apiPathValue;
							// baseUrlBearerToken = options.baseUrl2BearerToken;
							dynamicBaseUrlBearerToken = baseUrl2BearerToken;
						} else if (baseUrlValue === "baseUrl3") {
							url = options.baseUrl3 + apiPathValue;
							// baseUrlBearerToken = options.baseUrl3BearerToken;
							dynamicBaseUrlBearerToken = baseUrl3BearerToken;
						} else if (baseUrlValue === "baseUrl4") {
							url = options.baseUrl4 + apiPathValue;
							// baseUrlBearerToken = options.baseUrl4BearerToken;
							dynamicBaseUrlBearerToken = baseUrl4BearerToken;
						} else if (baseUrlValue === "baseUrl5") {
							url = options.baseUrl5 + apiPathValue;
							// baseUrlBearerToken = options.baseUrl5BearerToken;
							dynamicBaseUrlBearerToken = baseUrl5BearerToken;
						}

						bu = url;
						bubt = dynamicBaseUrlBearerToken;

						console.log("url, dynamicBaseUrlBearerToken", url, dynamicBaseUrlBearerToken);

						// if nothing is provided, for authentication
						if (!apiUsernameValue && !apiPasswordValue && !apiBearerTokenValue && !dynamicBaseUrlBearerToken) {
							// add extra headers, if needed
							let config = {
								headers: {},
							};

							if (apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${url}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if bearer token is provided but nothing else, for authentication
						else if (!apiUsernameValue && !apiPasswordValue && !apiBearerTokenValue && dynamicBaseUrlBearerToken) {
							// add extra headers, if needed
							let config = {
								headers: {
									"Content-Type": "application/json",
									Authorization: `Bearer ${dynamicBaseUrlBearerToken}`,
								},
							};

							if (apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${url}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if username & password are provided but nothing else, for authentication
						else if (apiUsernameValue && apiPasswordValue && !apiBearerTokenValue && !dynamicBaseUrlBearerToken) {
							// add extra headers, if needed
							let config = {
								headers: {
									Authorization: "Basic " + btoa(apiUsernameValue + ":" + apiPasswordValue),
								},
							};

							if (apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${url}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}
					}

					// if only custom url is provided and its a POST call
					else if (customUrlValue && apiBodyValue && (!baseUrlValue || baseUrlValue === "null") && !apiPathValue && apiMethodValue === "POST") {
						// if nothing is provided, for authentication
						if (!apiUsernameValue && !apiPasswordValue && !apiBearerTokenValue) {
							// add extra headers, if needed
							let config = {
								method: "POST",
								body: apiBodyValue,
								headers: {
									"Content-Type": "application/json",
								},
							};

							if (apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${customUrlValue}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if bearer token is provided but nothing else, for authentication
						else if (!apiUsernameValue && !apiPasswordValue && apiBearerTokenValue) {
							// add extra headers, if needed
							let config = {
								method: "POST",
								body: apiBodyValue,
								headers: {
									"Content-Type": "application/json",
									Authorization: `Bearer ${apiBearerTokenValue}`,
								},
							};

							if (apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${customUrlValue}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if username & password are provided but nothing else, for authentication
						else if (apiUsernameValue && apiPasswordValue && !apiBearerTokenValue) {
							// add extra headers, if needed
							let config = {
								method: "POST",
								body: apiBodyValue,
								headers: {
									"Content-Type": "application/json",
									Authorization: "Basic " + btoa(apiUsernameValue + ":" + apiPasswordValue),
								},
							};

							if (apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${customUrlValue}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}
					}

					// if only base url is provided and its a POST call
					else if (!customUrlValue && apiBodyValue && (baseUrlValue || !baseUrlValue === "null") && apiPathValue && apiMethodValue === "POST") {
						// concatenating base url and its remaining part
						let url, dynamicBaseUrlBearerToken;
						let forDownloadUrl;
						
						if (baseUrlValue === "baseUrl1") {
							url = options.baseUrl1 + apiPathValue;
							// baseUrlBearerToken = options.baseUrl1BearerToken;
							dynamicBaseUrlBearerToken = baseUrl1BearerToken;
						} else if (baseUrlValue === "baseUrl2") {
							url = options.baseUrl2 + apiPathValue;
							// baseUrlBearerToken = options.baseUrl2BearerToken;
							dynamicBaseUrlBearerToken = baseUrl2BearerToken;
						} else if (baseUrlValue === "baseUrl3") {
							url = options.baseUrl3 + apiPathValue;
							// baseUrlBearerToken = options.baseUrl3BearerToken;
							dynamicBaseUrlBearerToken = baseUrl3BearerToken;
						} else if (baseUrlValue === "baseUrl4") {
							url = options.baseUrl4 + apiPathValue;
							// baseUrlBearerToken = options.baseUrl4BearerToken;
							dynamicBaseUrlBearerToken = baseUrl4BearerToken;
						} else if (baseUrlValue === "baseUrl5") {
							url = options.baseUrl5 + apiPathValue;
							// baseUrlBearerToken = options.baseUrl5BearerToken;
							dynamicBaseUrlBearerToken = baseUrl5BearerToken;
						}

						const urlDownload1 = window.top.location.href;
						const isSubstringPresent1 = (urlDownload1.indexOf("/editor/") !== -1 && urlDownload1.indexOf("?projectId=") !== -1) || urlDownload1.indexOf("/large_preview/") !== -1 || urlDownload1.indexOf("/tab_preview/") !== -1 || urlDownload1.indexOf("/mobile_preview/") !== -1 || urlDownload1.indexOf("/fragment_editor/") !== -1;
       			 		if (!isSubstringPresent1) {
       			 			// running in download
       			 			url = forDownloadUrl;
       			 		}

						bu = url;
						bubt = dynamicBaseUrlBearerToken;
						console.log("url, dynamicBaseUrlBearerToken", url, dynamicBaseUrlBearerToken);

						// if nothing is provided, for authentication
						if (!apiUsernameValue && !apiPasswordValue && !apiBearerTokenValue && !dynamicBaseUrlBearerToken) {
							// add extra headers, if needed
							let config = {
								method: "POST",
								body: apiBodyValue,
								headers: {
									"Content-Type": "application/json",
								},
							};

							if (apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${url}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if bearer token is provided but nothing else, for authentication
						else if (!apiUsernameValue && !apiPasswordValue && !apiBearerTokenValue && dynamicBaseUrlBearerToken) {
							// add extra headers, if needed
							let config = {
								method: "POST",
								body: apiBodyValue,
								headers: {
									"Content-Type": "application/json",
									Authorization: `Bearer ${dynamicBaseUrlBearerToken}`,
								},
							};

							if (apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${url}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if username & password are provided but nothing else, for authentication
						else if (apiUsernameValue && apiPasswordValue && !apiBearerTokenValue && !dynamicBaseUrlBearerToken) {
							// add extra headers, if needed
							let config = {
								method: "POST",
								body: apiBodyValue,
								headers: {
									"Content-Type": "application/json",
									Authorization: "Basic " + btoa(apiUsernameValue + ":" + apiPasswordValue),
								},
							};

							if (apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${url}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}
					}

					console.log("apiData:", apiData);

					// if apiData response is an array-of-objects & not a direct javascript object
					if (this.isArrayofObjects(apiData)) {
						// -1 because the first original container will always be there
						repetitionCount = apiData.length - 1;
						this.set({RepetitionCount: repetitionCount});
						// console.log("repetitionCount1", repetitionCount);
					} else if (!this.isArrayofObjects(apiData) && (typeof apiData === 'object') && nestedArrOfObjKeynameValue) {
						// if the array-of-objects is nested in a key in the apiData response object
						// check to confirm if its an array-of-objects
						if (this.isArrayofObjects(apiData[`${nestedArrOfObjKeynameValue}`])) {
							// -1 because the first original container will always be there
							repetitionCount = apiData[`${nestedArrOfObjKeynameValue}`].length - 1;
							this.set({RepetitionCount: repetitionCount});
							// console.log("repetitionCount2", repetitionCount);
						}
					}

					// saving response in session storage also
					if (apiData) {
						sessionStorage.setItem(`DynamicCarousel_APIResp_${this.getAttributes().id ? this.getAttributes().id : 'undefined'}`, JSON.stringify(apiData));
					}
	    		},

				clearClones() {
					let mainContainer = this;

				    // Get the first div with class 'dynamic-carousel-inner-container'
				    let clones = mainContainer.find('.dynamic-carousel-inner-container');

				    clones.forEach((clone, idx) => {
				    	// leave the first clone/original and remove the rest
				    	if (idx !== 0) {
				    		clone.remove();
				    	}
				    });
				},

	    		RepetitionCountHandler() {
	    			showGrowingSpinner(); // Show spinner

				    let { RepetitionCount } = this.props();
				    console.log("RepetitionCount:", RepetitionCount);

				    let mainContainer = this;

				    // Get the first div with class 'dynamic-carousel-inner-container'
				    let innerContainer = mainContainer.find('.dynamic-carousel-inner-container')[0];

				    // leave the first clone/original and remove the rest everytime its plotted again (api change, RepetitionCount trait change)
				    this.clearClones();

				    // Function to recursively store the IDs of all elements
				    const storeIds = (component, idArray) => {
				        idArray.push(component.getId());
				        component.components().forEach((childComponent) => {
				            storeIds(childComponent, idArray);
				        });
				    };

				    // Store the original IDs in an array
				    let originalIds = [];
				    storeIds(innerContainer, originalIds);
				    console.log("originalIds", originalIds);

				    // Remove all existing clones, keeping only the first original container
				    let existingContainers = mainContainer.find('.dynamic-carousel-inner-container');
				    existingContainers.forEach((dcic, idx) => {
				        if (idx !== 0) dcic.remove(); // Remove all but the first one
				    });

				    // Adjust the count of existing containers to reflect the removal of clones
				    let existingContainersCount = mainContainer.find('.dynamic-carousel-inner-container').length - 1;

				    if (innerContainer && RepetitionCount) {
				        // Clone the innerContainer and append as per the RepetitionCount
				        for (let i = 1; i <= RepetitionCount; i++) {
				            let clonedComponent = innerContainer.clone();

				            // Function to recursively add the data-repl-id to each component and its children
				            const addDataReplId = (component, index, originalIdsArray) => {
				                let originalId = originalIdsArray.shift();
				                component.addAttributes({ 'data-repl-id': `${originalId}_${index}` });

				                // Recursively add data-repl-id to all child components
				                component.components().forEach((childComponent) => {
				                    addDataReplId(childComponent, index, [...originalIdsArray]); // Pass a copy of originalIdsArray
				                });
				            };

				            // Add data-repl-id to the cloned component and its children
				            addDataReplId(clonedComponent, i, [...originalIds]);

				            // Append the cloned component to the mainContainer
				            mainContainer.append(clonedComponent);
				        }
				    }

				    hideGrowingSpinner(); // Hide spinner
				},
	    	},

	    	view: {
	    		events: {
					"click .update-clones-btn": "updateClones",
				},

				// rerun updateClones when a new block (component) is dropped in the original container & so that the ids of the new elements can be updated in the session storage
				updateClones(event) {
				    showGrowingSpinner(); // Show spinner

				    // Ensure the spinner is visible before starting heavy operations
				    setTimeout(() => {
				        let { RepetitionCount } = this.model.props();
				        let mainContainer = this.model;

				        // Get the first div with class 'dynamic-carousel-inner-container'
				        let innerContainer = mainContainer.find('.dynamic-carousel-inner-container')[0];

				        // Function to recursively store the IDs of all elements
				        const storeIds = (component, idArray) => {
				            idArray.push(component.getId());
				            component.components().forEach((childComponent) => {
				                storeIds(childComponent, idArray);
				            });
				        };

				        // Store the original IDs in an array
				        let originalIds = [];
				        storeIds(innerContainer, originalIds);

				        // Remove all old clones, keeping only the first original container
				        let existingContainers = mainContainer.find('.dynamic-carousel-inner-container');
				        for (let i = 1; i < existingContainers.length; i++) {
				            existingContainers[i].remove();
				        }

				        // Adjust the count of existing containers to reflect the removal of clones
				        let existingContainersCount = 0;

				        if (innerContainer && RepetitionCount) {
				            // Clone the innerContainer and append as per the RepetitionCount
				            for (let i = 1; i <= RepetitionCount; i++) {
				                let clonedComponent = innerContainer.clone();

				                // Function to recursively add the data-repl-id to each component and its children
				                const addDataReplId = (component, index, originalIdsArray) => {
				                    let originalId = originalIdsArray.shift();
				                    component.addAttributes({ 'data-repl-id': `${originalId}_${index}` });

				                    // Recursively add data-repl-id to all child components
				                    component.components().forEach((childComponent) => {
				                        addDataReplId(childComponent, index, originalIdsArray);
				                    });
				                };

				                // Add data-repl-id to the cloned component and its children
				                addDataReplId(clonedComponent, existingContainersCount + i, [...originalIds]);

				                // Append the cloned component to the mainContainer
				                mainContainer.append(clonedComponent);
				            }
				        }

				        // to rerun the component script where it will update the ids in the session storage
				        this.render();

				        hideGrowingSpinner(); // Hide spinner
				    }, 0); // Allow time for the spinner to appear
				},

	    		onRender({ el, model }) {
	    			if (el.className.includes("custom-dynamic-carousel-container")) {
	    				this.model.apiPlotting();
	    			}
	    			
	    			if (!window.top.location.href.includes("large_preview/")) {	    				
	    				console.log('el', el);
	    				if (el.className.includes("custom-dynamic-carousel-container")) {
		    				// button to unify the id of all accordion items so that they don't affect each other
		    				let updateCloneBtn = document.createElement("button");
							updateCloneBtn.setAttribute("class", "update-clones-btn");
							updateCloneBtn.setAttribute("title", "Click to update all the clones.");

							updateCloneBtn.style.marginTop = '0.5%';
							// updateCloneBtn.style.marginBottom = '0.5%';

							updateCloneBtn.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16"><path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0"/></svg>';

							// el.getElementsByClassName("dynamic-carousel-inner-container")[0].insertAdjacentElement("beforebegin", updateCloneBtn);
							if (el) el.appendChild(updateCloneBtn);
	    				}	    				
	    			}
	    		},
	    	}
	    });

		// function updateClones(comp) {
		//     let { RepetitionCount } = comp.props();
		//     let mainContainer = comp;

		//     // Get the first div with class 'dynamic-carousel-inner-container'
		//     let innerContainer = mainContainer.find('.dynamic-carousel-inner-container')[0];

		//     // Function to recursively store the IDs of all elements
		//     const storeIds = (component, idArray) => {
		//         idArray.push(component.getId());
		//         component.components().forEach((childComponent) => {
		//             storeIds(childComponent, idArray);
		//         });
		//     };

		//     // Store the original IDs in an array
		//     let originalIds = [];
		//     storeIds(innerContainer, originalIds);

		//     // Remove all old clones, keeping only the first original container
		//     let existingContainers = mainContainer.find('.dynamic-carousel-inner-container');
		//     for (let i = 1; i < existingContainers.length; i++) {
		//         existingContainers[i].remove();
		//     }

		//     // Adjust the count of existing containers to reflect the removal of clones
		//     let existingContainersCount = 0;

		//     if (innerContainer && RepetitionCount) {
		//         // Clone the innerContainer and append as per the RepetitionCount
		//         for (let i = 1; i <= RepetitionCount; i++) {
		//             let clonedComponent = innerContainer.clone();

		//             // Function to recursively add the data-repl-id to each component and its children
		//             const addDataReplId = (component, index, originalIdsArray) => {
		//                 let originalId = originalIdsArray.shift();
		//                 component.addAttributes({ 'data-repl-id': `${originalId}_${index}` });

		//                 // Recursively add data-repl-id to all child components
		//                 component.components().forEach((childComponent) => {
		//                     addDataReplId(childComponent, index, originalIdsArray);
		//                 });
		//             };

		//             // Add data-repl-id to the cloned component and its children
		//             addDataReplId(clonedComponent, existingContainersCount + i, [...originalIds]);

		//             // Append the cloned component to the mainContainer
		//             mainContainer.append(clonedComponent);
		//         }
		//     }
		// }

		// // rerun updateClones when a new block (component) is dropped in the original container & so that the ids of the new elements can be updated in the session storage
		// editor.on("block:drag:stop", (block) => {
		// 	// console.log("+block:drag:stop+", block);

		// 	let components = editor.getWrapper();
		// 	let allDynamicCarousels = components.find('.custom-dynamic-carousel-container');

		// 	if (allDynamicCarousels.length) {
		// 		allDynamicCarousels.forEach(comp => {
		// 			// to rerun the script so that new IDs are added to session storage
		// 			comp.view.render();

		// 			// Call the updateClones function to refresh clones based on updated innerContainer
        //     		updateClones(comp);
		// 		});
		// 	}
		// });

		// // rerun updateClones when a component is selected and repositioned within the original container & so that the ids of the new elements can be updated in the session storage
		// editor.on("component:drag:end", (status) => {
		// 	// console.log("+component:drag:end+", status);

		// 	let components = editor.getWrapper();
		// 	let allDynamicCarousels = components.find('.custom-dynamic-carousel-container');

		// 	if (allDynamicCarousels.length) {
		// 		allDynamicCarousels.forEach(comp => {
		// 			// to rerun the script so that new IDs are added to session storage
		// 			comp.view.render();

		// 			// Call the updateClones function to refresh clones based on updated innerContainer
        //     		updateClones(comp);
		// 		});
		// 	}
		// });

		// // rerun updateClones when a component is selected and its style is updated within the original container & so that the ids of the new elements can be updated in the session storage
		// editor.on("style:property:update", (property) => {
		// 	// console.log("+style:property:update+", property);

		// 	if (property.value !== "") {
		// 		let components = editor.getWrapper();
		// 		let allDynamicCarousels = components.find('.custom-dynamic-carousel-container');

		// 		if (allDynamicCarousels.length) {
		// 			allDynamicCarousels.forEach(comp => {
		// 				// to rerun the script so that new IDs are added to session storage
		// 				// comp.view.render();

		// 				// Call the updateClones function to refresh clones based on updated innerContainer
	    //         		updateClones(comp);
		// 			});
		// 		}
		// 	}			
		// });
    }
};

export default CustomDynamicCarouselPlugin;
